import { render, staticRenderFns } from "./App.vue?vue&type=template&id=4de1e384&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRangeSlider } from 'vuetify/lib/components/VRangeSlider';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSparkline } from 'vuetify/lib/components/VSparkline';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VApp,VCard,VCardText,VCardTitle,VDataTable,VForm,VIcon,VImg,VMain,VRangeSlider,VSheet,VSlider,VSparkline,VSwitch})
