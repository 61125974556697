<template>
  <v-app>
    <v-main>
      <template>
        <v-card class="mx-auto text-center pa-4" color="white" max-width="600">
          <v-img
            src="https://gopeer.ca/wp-content/uploads/2020/07/goPeer-Blue-368x100-1.png"
            width="150"
          ></v-img>

          <v-card-title>Investment Calculator</v-card-title>

          <p>
            <a
              href="https://lifemission.blog/2022/04/18/gopeer-investment-calculator/"
              >How to use this Calculator</a
            >
            <v-icon  color="blue darken-2">
              mdi-help-circle-outline
            </v-icon>
          </p>

          <v-form>
            <p>Initial Investment:</p>
            <v-slider
              :value="investmentAmount"
              :thumb-size="48"
              :min="0"
              :max="10000"
              step="100"
              ticks
              thumb-label="always"
              @change="investmentAmountChanged"
            >
              <template v-slot:thumb-label="{ value }"> ${{ value }} </template>
            </v-slider>

            <div>Credit Score Range:</div>
            <v-range-slider
              :tick-labels="ticksLabels"
              :min="0"
              :max="11"
              v-model="riskRange"
              ticks="always"
              @change="riskRangeChanged"
              thumb-label
            >
              <template v-slot:thumb-label="{ value }">
                {{ ticksLabels[value] }}
              </template>
            </v-range-slider>

            <v-switch
              v-model="autoInvest"
              inset
              :label="`Auto Invest   ${autoInvest ? 'enabled' : 'disabled'}`"
              @change="autoInvestChanged"
            ></v-switch>
          </v-form>

          <v-card-text>
            <div>Estimated Rate of Return</div>
            <p class="text-h4 text--primary">{{ estimatedRateOfReturn }}%</p>
          </v-card-text>

          <v-card-text>
            <div>Total Account Value</div>
            <p class="text-h4 text--primary">${{ totalAccountValue }}</p>
          </v-card-text>

          <v-card-text>
            <v-sheet color="blue lighten-5">
              <v-sparkline
                :value="incomeProjection"
                color="blue"
                height="100"
                padding="24"
                smooth
                stroke-linecap="round"
                label="Interest"
              >
                <template v-slot:label="item"> ${{ item.value }} </template>
              </v-sparkline>
            </v-sheet>
          </v-card-text>

          <v-card-text>
            <div class="text-h4 font-weight-thin">
              Income Projection (5 years)
            </div>
          </v-card-text>

          <template>
            <v-data-table
              :headers="incomeProjectionTableHeaders"
              :items="incomeProjectionTable"
              :items-per-page="5"
              class="elevation-1"
            ></v-data-table>
          </template>
        </v-card>
      </template>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      incomeProjection: [],
      incomeProjectionTable: [],
      incomeProjectionTableHeaders: [
        { text: "Month", value: "month" },
        { text: "Payments to Date", value: "payments" },
        { text: "Principle Repaid To Date", value: "principal" },
        { text: "Interest Earned To Date", value: "interest" },
        { text: "Outstanding / Account Value ", value: "outstanding" },
      ],
      estimatedRateOfReturn: "14.82",
      totalInterest: 0,
      totalAccountValue: 0,
      investmentAmount: 0,
      investmentTerm: 60,
      riskRange: [3, 8],
      autoInvest: false,
      ticksLabels: [
        "A+",
        "A",
        "A-",
        "B+",
        "B",
        "B-",
        "C+",
        "C",
        "C-",
        "D+",
        "D",
        "E",
      ],
    };
  },
  methods: {
    investmentAmountChanged(value) {
      this.investmentAmount = value;
      console.log(`Investment Amount Changed: ${value}`);
      this.updateAmort();
    },
    autoInvestChanged(value) {
      console.log(`Auto Invest Switched: ${value}`);
      this.updateAmort();
    },
    riskRangeChanged(value) {
      this.riskRange = value;
      //console.log(`Risk Range Changed: ${value}`);

      // average returns by credit score, straight from gopeers site
      var returns = [
        7.8, 8.4, 9.6, 11, 12.4, 13.7, 16.4, 17.3, 18.1, 19.8, 22.3, 25,
      ];

      var total = 0;
      var count = 0;

      returns.forEach(function (item, index) {
        if (index >= value[0] && index <= value[1]) {
          total += item;
          count++;
        }
      });

      total / count;

      this.estimatedRateOfReturn = (total / count - 1.5).toFixed(2);

      this.updateAmort();
    },
    updateAmort() {
      var sparkline = [];
      var table = [];

      console.log(`Updated Sparkline: ${sparkline}`);

      var balance = this.investmentAmount;
      var interestRate = this.estimatedRateOfReturn / 100.0;
      var terms = this.investmentTerm;
      var monthlyRate = interestRate / 12;
      var payment =
        balance * (monthlyRate / (1 - Math.pow(1 + monthlyRate, -terms)));

      var totalPayments = 0;
      var totalInterest = 0;
      var totalPrinciple = 0;

      for (var count = 0; count < terms; ++count) {
        var interest = 0;
        var monthlyPrincipal = 0;

        interest = balance * monthlyRate;
        monthlyPrincipal = payment - interest;

        if (this.autoInvest) {
          balance = balance + interest;
        } else {
          balance = balance - monthlyPrincipal;
        }

        totalPayments = totalPayments + payment;
        totalInterest = totalInterest + interest;
        totalPrinciple = totalPrinciple + monthlyPrincipal;

        table.push({
          month: count + 1,
          payments: totalPayments.toFixed(2),
          outstanding: balance.toFixed(2),
          interest: totalInterest.toFixed(2),
          principal: totalPrinciple.toFixed(2),
        });

        if ((count + 1) % 12 == 0) {
          sparkline.push(Math.round(totalInterest));
        }
      }

      this.totalAccountValue = (this.investmentAmount + totalInterest).toFixed(
        2
      );
      this.totalInterest = totalInterest.toFixed(2);
      this.incomeProjection = sparkline;
      this.incomeProjectionTable = table;
    },
  },
};
</script>
